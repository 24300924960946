
(function ($) {
	'use strict';
	$(function () {

		var lastScrollTop = 0;
		var scrollTop = 0;
		var scrollUpDelta = 100;
		var minScrollTop = 220;
		var body = $(document.body);
		var win = $(window);

		function setNavClass() {

			scrollTop = win.scrollTop();

			if (scrollTop - lastScrollTop > 0 || Math.abs(lastScrollTop - scrollTop) >= scrollUpDelta) {

				if (scrollTop > lastScrollTop && scrollTop >= minScrollTop) {
					body.addClass('hide-topbar');
				} else {
					body.removeClass('hide-topbar');
				}

				lastScrollTop = scrollTop;
			}
		}

		$(window).scroll(setNavClass);

	});

	// Open navigation menu
	$('.topbar__button').click(function () {
		$('.topbar').toggleClass('mobile-open');
	});

	// Close navigation menu
  $('body').click(function(e){
    if($(e.target).closest('.site-header').length === 0){
      $('.topbar').removeClass('mobile-open');
    }
  });

	var isMobile = window.hasOwnProperty('om') ? window.om.isMobile : false;

	// Menu logic for tablets
	if (isMobile && ($(window).width() > 1023)) {

		$(document).click(function(e) {

			if($('.menu-item-has-children.open').length !== 0) {
				$('.menu-item-has-children.open').removeClass('open');
			}

		});

		$(".menu-item-has-children > a").click(function(e) {

			var parent = $(this).parent("li");

			if (parent.hasClass("open")) {
				return true; // don't stop propagation but don't continue either
			}

			parent.siblings().removeClass("open");
			parent.siblings().find('.menu-item-has-children.open').removeClass("open");
			parent.addClass("open");

			e.stopPropagation();
			e.preventDefault();

		});

	} else {

		$(".menu-item-has-children").click(function(e) {
			if(e.target != this) return;
			$(this).toggleClass("open");
		});

	}

	$(document).click(function(event) {
		//if you click on anything except the modal itself or the "open modal" link, close the modal
		if (!$(event.target).closest('.modal-dialog,.modal-link').length) {
			$("body").find('.modal').css('display', 'none');
			$("body").css('overflow', 'auto');
		}
	});


	$( '.modal-link' ).click(function( event ) {
		event.preventDefault();

		var id = $(this).data('id');

		$.ajax({
			url: '/wp-admin/admin-ajax.php',
			data:
				{
					id: id,
					action: 'modal_show_post'
				},

			success: function (response) {
				if (response['error'] == '1') {
					$('#modalTitle').html("Error");
					$('#modalBody').html("No post found! Sorry :(");
				} else {
					$('#modalBg').css("background-image", "url(" + response['bg'] + ")");
					$('#modalTitle').html(response['post_title']);
					$('#modalContent').html(response['post_content']);
					$('#modalLinks').html('');
					if(response.hasOwnProperty('post_links') && Array.isArray(response['post_links'])){
            for(var i = 0; i<response['post_links'].length; i++){
              var link = response['post_links'][i];
              $('#modalLinks').append('' +
                  '<a target="_blank" href="'+link.modal_link_url+'"><img src="'+link.modal_link_img+'"></a>' +
                  '');
            }
          }
				}
				$('#otavaModal').modal({
					showClose: false,
				});
			}
		});
	});

})(jQuery);
